'use strict';

import React from 'react';
import * as Arn from "@aws-sdk/util-arn-parser";
import Dashboard from './CloudwatchDashboard/Dashboard';

class CloudWatchDashboard extends React.Component {
    dashboardContainer;
    dashboard;

    constructor(props) {
        super(props);
        this.displayDashboard = this.displayDashboard.bind(this);
    }

    componentDidMount() {

        let arn = Arn.parse(this.props.broker.brokerInfo.arn);
        let partition = arn.partition;

        this.dashboard = new Dashboard(
            this.dashboardContainer,
            this.props.title,
            this.props.region,
            {
                heightUnit: 40,
                widgetMarginX: 10,
                widgetMarginY: 10,
                staticWidgets: true,
                displayMode: 'static',
                style: 'polaris',
                internal: {
                    accountId: this.props.serviceAccountId,
                }
            },
            {},
            "",
            partition
        );

        this.dashboard.on('ready', this.displayDashboard);
    }

    displayDashboard() {
        this.dashboard.update(this.props.model, false);
    }

    render() {
        return <div ref={(div) => this.dashboardContainer = div}/>;
    }
}

export default CloudWatchDashboard;