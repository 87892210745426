import { Region } from "./types";

export const COLOR_SUCCESS = '#0b9e24';
export const COLOR_ERROR = 'red';
export const COLOR_INFO = '#0073bb';

let regionsList : Region[] | undefined = undefined;

export function getAttributeValues(value: string) {
    return value
        .split("\n")
        .map(line => line.split(/(\[[^\]]+\]\([^)]+\))/)
            .map(linkCandidate => {
                const urlMatch = linkCandidate.match(/\[([^\]]+)\]\(([^)]+)\)/) || [];
                const [, text, link] = urlMatch;
                const textValue = text || linkCandidate || '';
                const isCombo = textValue.includes('(') || textValue.includes('|');
                return {
                    isUrl: !!link,
                    link,
                    text: textValue,
                    isCombo
                };
            })
            .filter(v => !!v.text.trim())
        )
}

export function buildOldConsoleUrl(brokerId: string, domain: string, airport: string) {
    return `https://amazonmq-ops-console-prod-pdx.corp.amazon.com/describeBroker?brokerId=${brokerId}&domain=${domain}&region=${airport}&noredirect`
}

export function generateRegionsOptions(regionsList: Region[]) {
    return regionsList.map((region) => {
        return {
            label: region["airportCode"] + ' (' + region["regionCode"] + ')',
            id: region["airportCode"].toLowerCase(),
            regionCode: region["regionCode"]
        }
    })
}

export function seperateStringContents(value: string) {
    return value.split(/\(|\|/)
}

export function getDomainFromHost() {
    let host = window.location.host;
    if (host.indexOf(".people.aws.dev") >= 0) {
        // https://opsconsole.{username}.people.aws.dev/
        let domain = host.substring(host.indexOf('opsconsole.') + 'opsconsole.'.length, host.indexOf(".people.aws.dev"));
        return "dev-" + domain;
    } else if (host.indexOf(".beta.opsconsole.amazonmq.") >= 0) {
        return "beta";
    } else if (host.indexOf(".gamma.opsconsole.amazonmq.") >= 0) {
        return "gamma";
    } else {
        return "prod";
    }
}

export function getRegionFromHost() {
    let host = window.location.host;
    if (host.indexOf('localhost.a2z.com') >= 0) {
        return "us-west-2";
    } else if (host.indexOf(".people.aws.dev") >= 0) {
        return "us-west-2";
    } else if (host.indexOf(".beta.opsconsole.amazonmq.") >= 0) {
        return host.substring(0, host.indexOf(".beta."));
    } else if (host.indexOf(".gamma.opsconsole.amazonmq.") >= 0) {
        return host.substring(0, host.indexOf(".gamma."));
    } else {
        return host.substring(0, host.indexOf(".opsconsole.amazonmq."));
    }
}

export function buildHostForDomainAndRegion(domain: string, regionNotAirport: string) {
    if (isLocalhost()) {
        return 'localhost.a2z.com:3000'
    } else if (domain === "prod") {
        if (regionNotAirport === "cn-north-1" || regionNotAirport === "cn-northwest-1") {
            return `${regionNotAirport}.opsconsole.amazonmq.a2z.org.cn`
        }
        return `${regionNotAirport}.opsconsole.amazonmq.aws.dev`
    } else if (isDevDomain(domain)) {
        let username = extractUsernameFromDevDomain(domain);
        return `opsconsole.${username}.people.aws.dev`
    } else {
        if (regionNotAirport === "cn-north-1" || regionNotAirport === "cn-northwest-1") {
            return `${regionNotAirport}.${domain}.opsconsole.amazonmq.a2z.org.cn`
        }
        return `${regionNotAirport}.${domain}.opsconsole.amazonmq.aws.dev`
    }
}

export function isLocalhost() {
    return window.location.href.indexOf('localhost.a2z.com') >= 0;
}

export function isDevDomain(domain: string) {
    return domain.startsWith("dev-");
}

export function extractUsernameFromDevDomain(domain: string) {
    return domain.substring(4);
}

export async function getRegions(): Promise<Region[]> {
    if (!regionsList) {
        const fullHostname = window.location.protocol + '//' + window.location.host;
        const regions = await fetch(`${fullHostname}/regions.json`)
        regionsList = await regions.json() as Region[];
    }
    return Promise.resolve(regionsList!)
}

export function isRoundtable(airportCode: string) {
    return airportCode === "bjs" || airportCode === "zhy";
}

export function createIsengardLink(accountId: string, role: string, destination: string) {
    return `https://isengard.amazon.com/federate?account=${accountId}&role=${role}&destination=${encodeURIComponent(destination)}`;
}