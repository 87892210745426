import React, { useEffect, useState } from 'react';

import { BrokerInstance, Ec2Instance, RabbitBrokerQueue, RabbitBrokerQueues } from './types';

import {
    Button,
    Checkbox,
    Flashbar,
    Select,
    Spinner,
    Table,
    TableSorting,
} from '@amzn/awsui-components-react';
import { Broker } from './types';
import { getRabbitBrokerQueues } from './api/api';
import CopyToClipboardButton from './copyToClipboardButton';

type SummaryProps = {
    brokerQueues: RabbitBrokerQueues,
    onStateToggle: (queueState: string) => void,
    enabledStates: string[]
}

function getCountByState(brokerQueues: RabbitBrokerQueues | undefined): Map<string, number> {
    if (brokerQueues === undefined) {
        return new Map();
    }

    let countByState = new Map<string, number>();
    for (let i = 0; i < brokerQueues.queues.length; i++) {
        let queue = brokerQueues.queues[i];
        if (!countByState.get(queue.state)) {
            countByState.set(queue.state, 1);
        } else {
            let currentCount : number = countByState.get(queue.state) || 0;
            countByState.set(queue.state, currentCount + 1);
        }
    }
    return countByState;

}

const BrokerQueuesSummary : React.FC<SummaryProps> = ({brokerQueues, enabledStates, onStateToggle}) => {
    let countByState = getCountByState(brokerQueues);
    return (
        <div style={{display: 'flex', gap: 8, flexDirection: 'row', alignItems: 'center'}}>
            {[...countByState].map(([queueState, numberOfQueues]) => {
                return (
                    <div>
                        <Checkbox checked={enabledStates.indexOf(queueState) >= 0} onChange={(e) => {
                            e.preventDefault();
                            onStateToggle(queueState)
                        }}>{queueState} ({numberOfQueues})</Checkbox>
                    </div>
                )
            })}
        </div>
    )
}

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[] | undefined
}


const BrokerQueuesTab : React.FC<Props> = ({broker, brokerInstances}) => {

    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);
    const [brokerQueues, setBrokerQueues] = useState<RabbitBrokerQueues | undefined>(undefined);
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [enabledStates, setEnabledStates] = useState<string[]>(Array.from(getCountByState(brokerQueues).keys()));

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitBrokerQueues(broker.id, selectedInstance?.instanceId, broker.brokerInfo.cellId).then(response => {
            setBrokerQueues(response);
            if (refreshCnt === 0) {
                let defaultEnabledStates = Array.from(getCountByState(response).keys());

                // hide fullySynced by default as there might be a lot of them
                let fullySyncedIdx = defaultEnabledStates.indexOf('fullySynced');
                if (fullySyncedIdx >= 0) {
                    defaultEnabledStates.splice(fullySyncedIdx, 1);
                }
                // same for quorum queues
                let allOnlineIdx = defaultEnabledStates.indexOf('allOnline');
                if (allOnlineIdx >= 0) {
                    defaultEnabledStates.splice(allOnlineIdx, 1);
                }
                setEnabledStates(defaultEnabledStates);
            }
        }).catch(error => {
            console.error(error);
            setError("Could not load broker queues");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, refreshCnt, selectedInstance?.instanceId])

    let copyText = brokerQueues !== undefined ? JSON.stringify(brokerQueues.queues.filter(q => enabledStates.includes(q.state))) : "";

    return (<div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
                <CopyToClipboardButton disabled={loading} text={copyText} />
                <div style={{flexGrow: 1}} />
                <Button disabled={loading} icon="refresh" onClick={() => {
                    setRefreshCnt(refreshCnt + 1);
                }} />
        </div>
        {brokerInstances !== undefined &&
            <div>
                <h4>Select Instance Id:</h4>
                <Select
                    options={[{ label: 'Any Instance', id: 'any' }, ...brokerInstances.map(i => {
                        return {
                            label: i.instanceId,
                            id: i.instanceId
                        }
                    })]}
                    selectedId={selectedInstance === undefined ? "any" : selectedInstance?.instanceId}
                    onChange={(event: any) => {
                        if (event.detail.selectedId === "any") {
                            setSelectedInstance(undefined)
                        } else {
                            setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedId))
                        }
                    }}
                />
            </div>
        }
        {error && <div style={{marginTop: 8}}><Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar></div>}
        {loading && <Spinner /> }
        {!loading && !error && brokerQueues &&
            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <div>
                    <BrokerQueuesSummary enabledStates={enabledStates} brokerQueues={brokerQueues} onStateToggle={(state) => {
                       let newEnabledStates = [...enabledStates];
                       if (enabledStates.indexOf(state) >= 0) {
                            let idx = enabledStates.indexOf(state);
                            newEnabledStates.splice(idx, 1);
                       } else {
                            newEnabledStates.push(state);
                       }
                       setEnabledStates(newEnabledStates);
                    }} />
                </div>
                <Table
                    items={brokerQueues.queues.filter(q => enabledStates.indexOf(q.state) >= 0)}
                    columnDefinitions={[
                        {
                            id: "type",
                            header: "Type",
                            cell: (item: RabbitBrokerQueue) => item.type
                        },
                        {
                            id: "name",
                            header: "Name",
                            cell: (item: RabbitBrokerQueue) => item.name
                        },
                        {
                            id: "state",
                            header: "State",
                            cell: (item: RabbitBrokerQueue) => item.state
                        },
                        {
                            id: "vhost",
                            header: "vhost",
                            cell: (item: RabbitBrokerQueue) => item.vhost
                        },
                        {
                            id: "primaryNode",
                            header: "Primary Node",
                            cell: (item: RabbitBrokerQueue) => item.primaryNode
                        }
                    ]}
                >
                    <TableSorting
                        sortableColumns={[{id: 'state', field: 'state'}, {id: 'primaryNode', field: 'primaryNode'}, {id: 'type', field: 'type'}]}
                        sortingColumn='state'
                        sortingDescending={true} />
                </Table>
            </div>
        }
    </div>
    );
}

export default BrokerQueuesTab;
