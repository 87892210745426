import './navBar.css';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    Select
} from '@amzn/awsui-components-react';
import { Region, Domain } from './types';
import { useNavigate } from 'react-router';
import { buildHostForDomainAndRegion, buildOldConsoleUrl, generateRegionsOptions, getRegions } from './utils';
const ENTER_KEYCODE = 13;



type Props = {
    defaultDomain: string,
    defaultRegionOrAirportCode: string,
    shouldHideSearchBar?: boolean,
    username: string,
    selectedBrokerId?: string
}

function toggleDarkMode(event: any) {
    document.body.classList.toggle('awsui-polaris-dark-mode');
}

const NavBar : React.FC<Props> = ({defaultDomain, defaultRegionOrAirportCode, shouldHideSearchBar = false, username, selectedBrokerId}) => {

    const domains: Domain[] = [
        {
            "label": "Beta",
            "id": "beta"
        },
        {
            "label": "Gamma",
            "id": "gamma"
        },
        {
            "label": "Prod",
            "id": "prod"
        },
        {
            "label": `dev-${username}`,
            "id": `dev-${username}`
        }
    ]

    const [currentDomain, setCurrentDomain] = useState<Domain | undefined>(domains.find(d => d.id === defaultDomain));
    const [currentRegion, setCurrentRegion] = useState<Region | undefined>(undefined); // default 'pdx'
    const [searchVal, setSearchVal] = useState<string>("");
    const [regions, setRegions] = useState<Region[] | undefined>(undefined);
    const navigate = useNavigate();

    function findRegion(regions: Region[], regionOrAirportCode: string) {
        return regions.find(r => r.airportCode.toLowerCase() === defaultRegionOrAirportCode.toLowerCase() || r.regionCode.toLowerCase() === regionOrAirportCode.toLowerCase());
    }

    useEffect(() => {
        if (regions === undefined) {
            return;
        }
        setCurrentDomain(domains.find(d => d.id === defaultDomain));
        setCurrentRegion(findRegion(regions, defaultRegionOrAirportCode));
    }, [defaultDomain, defaultRegionOrAirportCode]);

    useEffect(() => {
        if (currentDomain === null || currentDomain === undefined) {
            return;
        }
        getRegions().then(regions => {
            setRegions(regions);
            setCurrentRegion(findRegion(regions, defaultRegionOrAirportCode));
        }).catch(error => {
            console.error(error);
        })
    }, [defaultRegionOrAirportCode])

    if (currentRegion === undefined || currentDomain === undefined || regions === undefined) {
        return <></>
    }

    function searchBroker(brokerId: string, selectedDomain: string, selectedRegionNotAirport: string) {
        let newHost = buildHostForDomainAndRegion(selectedDomain, selectedRegionNotAirport);
        if (window.location.host !== newHost) {
            window.location.href = `https://${newHost}/broker/${brokerId}`
        } else {
            navigate(`/broker/${brokerId}`);
        }
    }

    return (
        <div className="menu-wrapper">
        <div className="menu">
            <div className="title"><a style={{"color": "white"}} href={"/"}>Amazon MQ</a></div>
            <div className="title"><a style={{"color": "white", "marginLeft": "15px", cursor: 'pointer'}}target="_blank" href={"https://w.amazon.com/bin/view/AmazonMQ/Ops/Runbook"}>Runbook</a></div>
            {shouldHideSearchBar && <div style={{flexGrow: 1}} /> }
            {!shouldHideSearchBar &&
                <div className="searchBar">
                    <div className="dropDown-domain">
                        <Select
                            options={domains}
                            selectedId={currentDomain.id}
                            onChange={(event: any) => {
                                setCurrentDomain(domains.find(d => d.id === event.detail.selectedId));
                            }}>
                        </Select>
                    </div>
                    <div className="dropDown-region">
                        <Select
                            options={generateRegionsOptions(regions)}
                            selectedId={currentRegion.airportCode.toLowerCase()}
                            onChange={(event: any) => {
                                setCurrentRegion(regions.find(r => r.airportCode.toLowerCase() === event.detail.selectedId));
                            }}
                            loading={false}>
                        </Select>
                    </div>
                    <div className="search">
                        <Input
                            type="search"
                            placeholder='Search broker ID/ARN'
                            value={searchVal}
                            onKeydown={(event: any) => {
                                if (event.detail.keyCode === ENTER_KEYCODE) {
                                    searchBroker(searchVal, currentDomain.id, currentRegion.regionCode);
                                }
                            }}
                            onInput={(event: any) => {
                                setSearchVal(event.detail.value)
                            }}
                            autocomplete={false}
                        />
                    </div>
                    <div className="searchButton">
                        <Button text="Search" className="searchButton" onClick={(event: any) => {
                            searchBroker(searchVal, currentDomain.id, currentRegion.regionCode);
                        }}></Button>
                    </div>
                </div>
            }
            {selectedBrokerId !== undefined &&
                <div style={{marginRight: 8}}>
                    <Button onClick={() => {
                        window.location.href = buildOldConsoleUrl(selectedBrokerId, currentDomain.id, currentRegion.airportCode);
                    }}>Back to old console</Button>
                </div>
            }
            <div className="user">
                <a style={{"color": "white"}} target="_blank" href={"https://phonetool.amazon.com/users/" + username}>{username}</a>
                &nbsp;
                <a href="#" onClick={toggleDarkMode}>🌒︎</a>
            </div>
        </div>
        </div>
    );
    
}

export default NavBar;
