'use strict';

import React, { useEffect, useState } from 'react';
import { Flashbar, Spinner, Tabs } from '@amzn/awsui-components-react';
import CloudWatchDashboard from './cloudWatchDashboard';
import { Broker } from './types';
import { getCloudwatchDashboardLink } from './api/api';

type Props = {
    broker: Broker,
    snapshotId: string,
    serviceAccountId: string
}

const ServiceMetrics : React.FC<Props> = ({broker, snapshotId, serviceAccountId}) => {

    const [dashboards, setDashboards] = useState<any>(null);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getCloudwatchDashboardLink(broker.id, snapshotId).then(response => {
            setDashboards(response.link);
        }).catch(error => {
            console.error(error);
            setError("Could not load Cloudwatch Dashboards");
        }).finally(() => {
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <Spinner />
    }
    
    if (error) {
        return <Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar>
    }

    if (dashboards === null) {
        return <div></div>
    }

    let tabs: any[] = []

    Object.keys(dashboards).forEach(function (title) {
        let dashboardModel = dashboards[title];
        tabs.push({
            label: title,
            id: title,
            content:
                <CloudWatchDashboard
                    title={title}
                    broker={broker}
                    model={dashboardModel}
                    region={broker.region}
                    serviceAccountId={serviceAccountId}
                />
        });
    });
    
    return <Tabs tabs={tabs} activeTabId={Object.keys(dashboards)[0]}/>;

}

export default ServiceMetrics;