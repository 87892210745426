import '../describeBroker.css';
import React, { useEffect, useState } from 'react';
import '@amzn/awsui-components-react/index.css';
import { AppLayout, Button, Flashbar, Icon, Popover, Spinner, Tabs } from '@amzn/awsui-components-react';
import DestinationTable from '../destinationTable';
import ServiceMetrics from '../serviceMetrics';
import LogsPage from '../logsPage';
import SimIssuesTable from '../simIssuesTable';
import NavBar from '../navBar';
import WorkflowsTable from '../workflowsTable';
import BrokerDetailsTable from '../brokerDetailsTable';
import HOTWExecutionsTable from '../hotwExecutionsTable';
import CopyToClipboardButton from "../copyToClipboardButton";
import EventHistoryTable from "../eventHistoryTable";
import FailoverHistoryTable from "../failoverHistoryTable";
import BrokerReachout from "../reachouts/brokerReachout";
import CommandTemplates from "../commandTemplates";
import ConfigOverride from "../configOverride";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Broker, BrokerEngineType, Config, Ec2Instance } from '../types';
import { getBrokerById, getBrokerInstances, getSimMarkdown } from '../api/api';
import BrokerSummaryTable from '../brokerSummary';
import * as Arn from "@aws-sdk/util-arn-parser";
import ServiceMetricsTab from '../ServiceMetrics/ServiceMetricsTab';
import BrokerEnvironmentTab from '../brokerEnvironment';
import ClusterStatusTab from '../ClusterStatus/ClusterStatusTab';
import BrokerPoliciesTab from '../BrokerPolicies/BrokerPoliciesTab';
import BrokerQueuesTab from '../brokerQueues';
import { buildHostForDomainAndRegion, getDomainFromHost, getRegionFromHost } from '../utils';
import { getApiClient } from '../api/client';
import { InvalidIdentityTokenException } from '@aws-sdk/client-sts';
import { isConfigOverrideSupported } from '../features/features';

const LOADING_STATE = {
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED',
    UNLOADED: 'UNLOADED'
};

type CopyMarkDownButtonProps = {
    brokerId: string,
    snapshotId: string,
}

const CopyMarkDownButton : React.FC<CopyMarkDownButtonProps> = ({brokerId, snapshotId}) => {
    
    const [loadingState, setLoadingState] = useState<string>(LOADING_STATE.UNLOADED);
    const [markdownData, setMarkdownData] = useState<string | undefined>(undefined);

    useEffect(() => {
        fetchSimMarkdown(false);
    }, [])

    function isMarkdownLoaded() {
        return loadingState === LOADING_STATE.LOADED && markdownData !== undefined;
    }

    function cantCopyText() {
        alert('Temporary Error? Could not write to clipboard.');
    }

    const loadMarkdown = () => {
        fetchSimMarkdown(true);
    };

    function copySimMarkdown() {
        if (!isMarkdownLoaded()) {
            return cantCopyText();
        }
        if (markdownData === undefined) {
            return;
        }
        navigator.clipboard.writeText(markdownData)
            .catch(() => cantCopyText());
    }

    function fetchSimMarkdown(alertOnFail = false) {
        setLoadingState(LOADING_STATE.LOADING);
        getSimMarkdown(brokerId, snapshotId).then(response => {
            // f (response.ok && responseText.startsWith('#')) {
            setLoadingState(LOADING_STATE.LOADED);
            setMarkdownData(response.markdown);
        }).catch(error => {
            console.error(error);
            setLoadingState(LOADING_STATE.FAILED);
            if (alertOnFail) {
                alert('Failed to obtain markdown (console has details)');
            }
        });
    }

    switch (loadingState) {
        case LOADING_STATE.LOADING:
            return <Button icon="status-pending" text="Loading Markdown" disabled={true}/>
        case LOADING_STATE.UNLOADED:
            return <Button icon="share" text="Fetch Markdown" onClick={() => loadMarkdown()}/>
        case LOADING_STATE.FAILED:
            // for some reason uncommenting the icon here causes React to crash with infinite loop
            // if failed state occurred.
            return <Button /* icon="status-refresh" */ text="Retry Fetch Markdown" onClick={() => loadMarkdown()}/>
        case LOADING_STATE.LOADED:
            return <Popover
                size="small"
                position="bottom"
                triggerType="custom"
                dismissButton={false}
                content={
                    <span className="awsui-util-status-positive">
                        <Icon variant="success" name="status-positive"/> SIM Markdown Copied!
                    </span>
                }>
                <Button className="copymarkdown-button" icon="copy" text="Copy Markdown" variant="primary" onClick={() => copySimMarkdown()}/>
            </Popover>
    }
    return <Icon variant="error" name="status-negative"/>

}
    
function renderBrokerHeader(brokerId: string, isOurBroker: boolean, snapshotId: string) {
    return <div>
                <div className="brokerIdHeader" style={{display: 'flex', gap: 8}}>
                    <h2 id="brokerId">{brokerId}</h2>
                    <CopyToClipboardButton text={brokerId} textDescription={'broker id'}/>
                    {isOurBroker && <span className="awsui-util-status-info" style={{display: "flex", gap: 8}}><Icon name="status-info"/> Our Broker</span>}
                    {/* <h2>({domain.toUpperCase()}: {region})</h2> TODO !!!! */}
                    <span style={{flexGrow: 1}}></span>
                    <span id="copyBrokerMarkdown" style={{"float": "right"}}>
                        <CopyMarkDownButton brokerId={brokerId} snapshotId={snapshotId} />
                    </span>
                </div>
            </div>
}

type BrokerBodyProps = {
    broker: Broker,
}

const BrokerBody : React.FC<BrokerBodyProps> = ({broker}) => {

    const [brokerInstances, setBrokerInstances] = useState<Ec2Instance[] | undefined>(undefined);

    useEffect(() => {
        getBrokerInstances(broker.id, broker.snapshotId).then(response => {
            setBrokerInstances(response.instances);
        }).catch(error => {
            console.error(error);
        })
    }, [broker.id]);
    
    let tabs = [
        {
            label: 'Details',
            id: 'details',
            content: <BrokerDetailsTable
                broker={broker}
                brokerInstances={brokerInstances}
                instanceHistory={broker.instanceHistory}
            />
        },
        {
            label: 'Service Metrics',
            id: 'serviceMetrics',
            content: <ServiceMetricsTab
                    broker={broker}
                    brokerInstances={brokerInstances}
            />
        },
        {
            label: 'Service Metrics (Old)',
            id: 'serviceMetricsOld',
            content: <ServiceMetrics
                broker={broker}
                serviceAccountId={broker.summary.serviceAccountId}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Customer Metrics',
            id: 'customerMetrics',
            content: <DestinationTable
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Logs',
            id: 'logStreams',
            content: <LogsPage
                broker={broker}
            />
        },
        {
            label: 'SIM issues',
            id: 'simIssues',
            content: <SimIssuesTable
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Workflows',
            id: 'workflows',
            content: <WorkflowsTable
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'HOTW Executions',
            id: 'hotwExecutions',
            content: <HOTWExecutionsTable
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Event History',
            id: 'eventHistory',
            content: <EventHistoryTable
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Failover History',
            id: 'failoverHistory',
            content: <FailoverHistoryTable
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Reachout Templates',
            id: 'reachouts',
            content: <BrokerReachout
                broker={broker}
                snapshotId={broker.snapshotId}
            />
        },
        {
            label: 'Command Templates',
            id: 'commands',
            content: <CommandTemplates
                broker={broker}
                brokerInstances={brokerInstances}
            />
        }
    ];

    if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        if (isConfigOverrideSupported(broker)) {
            tabs.push({
                label: 'RabbitMQ Config Override',
                id: 'config_override',
                content: <ConfigOverride
                    broker={broker}
                    snapshotId={broker.snapshotId}
                />
            })
        }

        tabs.push({
            label: 'RabbitMQ Broker Environment',
            id: 'environment',
            content: <BrokerEnvironmentTab
                broker={broker}
                serviceAccountId={broker.summary.serviceAccountId}
                snapshotId={broker.snapshotId}
            />
        })

        tabs.push({
            label: 'RabbitMQ Broker Queues',
            id: 'queues',
            content: <BrokerQueuesTab
                broker={broker}
                brokerInstances={brokerInstances}
            />
        })

        tabs.push({
            label: 'RabbitMQ Cluster Status',
            id: 'cluster_status',
            content: <ClusterStatusTab
                broker={broker}
            />
        })

        tabs.push({
            label: 'RabbitMQ Broker Policies',
            id: 'broker_policies',
            content: <BrokerPoliciesTab
                broker={broker}
            />
        })
    }
    
    return (
        <div>
            {/* <div ref={ref => (this._div = ref)}></div> TODO */}
            {renderBrokerHeader(broker.id, broker.summary.ourBroker, broker.snapshotId)}
            <div className="infoTable">
                <BrokerSummaryTable
                    brokerSummary={broker.summary}
                />
                <Tabs
                    tabs={tabs}
                    activeTabId={
                        !!window.location.hash
                            ? window.location.hash.replace('#', '')
                            : 'details'
                    }
                    onChange={({detail}: any) => (detail.activeTabId && window.location.replace(`#${detail.activeTabId}`))}
                />
            </div>
        </div>
    )
}

const DescribeBroker : React.FC = () => {

    const [searchParams, _] = useSearchParams();
    let { brokerId } : any = useParams();

    if (!brokerId) {
        brokerId = searchParams.get("brokerId");
    }

    const [broker, setBroker] = useState<Broker | undefined>(undefined);
    const [config, setConfig] = useState<Config | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        getApiClient().then(api => {
            setConfig({
                username: api.user
            });
        }).catch(error => {
            console.error(error);
        })
    }, [])

    useEffect(() => {
        setError("");
        setBroker(undefined);
        if (brokerId === null) {
            return;
        }
        let domain = getDomainFromHost();
        if (Arn.validate(brokerId)) {
            let arn = Arn.parse(brokerId)
            let region = arn.region;
            if (arn.resource.split(":").length != 3) {
                return;
            }
            let actualBrokerId = arn.resource.split(":")[2]; // resource is broker:{brokerName}:{brokerId}
            let newHost = buildHostForDomainAndRegion(domain, region);
            window.location.href = `https://${newHost}/broker/${actualBrokerId}`
            return;
        }
        let region = getRegionFromHost();
        setLoading(true);
        getBrokerById(brokerId).then((response: Broker) => {
            setBroker(response);
        }).catch((error) => {
            console.log(error);
            if (error instanceof InvalidIdentityTokenException) {
                // this sometimes happens after Midway token expires and user is redirected to Midway login screen.
                console.log("Invalid token, redirecting to home page.");   
                window.location.href = "/";
                return;
            }
            if (!error.response) {
                setError("Internal server error. Please try again later.");
            } else if (error.response.status === 404) {
                setError("Broker " + brokerId + ` not found in ${domain}/${region}.`);
            } else {
                if (error.response.data && error.response.data.stacktrace) {
                    setError(error.response.data.stacktrace);
                } else {
                    setError("Internal server error. Please try again later.");
                }
            }
        }).finally(() => {
            setLoading(false);
        })
    }, [brokerId]);

    let defaultDomain = getDomainFromHost();
    let defaultRegion = getRegionFromHost();

    return (
        <div className='awsui'>
            <AppLayout
                content={
                    <div>
                        {config !== undefined &&
                            <NavBar
                                username={config.username}
                                defaultDomain={defaultDomain !== null ? defaultDomain : 'prod'}
                                defaultRegionOrAirportCode={defaultRegion !== null ? defaultRegion : 'pdx'}
                                selectedBrokerId={brokerId}
                            />
                        }
                        <div className="page-body">
                            {loading && 
                                <Spinner size='large'/>
                            }
                            {error !== undefined && error.length > 0 &&
                                <Flashbar items={
                                    [{
                                        "type": "error",
                                        "dismissible": false,
                                        "content": <span style={{whiteSpace: 'pre'}}>{error}</span>
                                    }]
                                }></Flashbar>
                            }
                            {broker === undefined && error === undefined &&
                            
                                <h1>Broker ${brokerId} not found.</h1>
                            }
                            {broker !== undefined &&
                                <BrokerBody broker={broker} />
                            }
                        </div>
                    </div>
                }
                toolsHide={true}
                navigationHide={true}
                headerSelector=".awsui menu"
            />
        </div>
    )
}

export default DescribeBroker;






