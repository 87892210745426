
export const QUEUE_TYPES = [
    "classic-empty",
    "classic",
    "classic-autodelete-empty",
    "classic-exclusive",
    "classic-exclusive-autodelete-empty",
    "quorum"
];

export const ALL_CLASSIC_QUEUE_STATES = [
    "fullySynced",
    "partiallySynced",
    "noSyncedMirrors",
    "missingStateNoSyncedMirrors",
    "missingStatePartiallySynced",
    "missingStateFullySynced",
    "missingStateNoPrimary",
    "unidentified",
    "syncing",
    "down",
    "stopped",
    "crashed",
    "flow"
];

export const ALL_QUORUM_QUEUE_STATES = [
    "allOnline",
    "quorumOnline",
    "minorityOnline",

    "allOnlineWrongSize",
    "quorumOnlineWrongSize",
    "minorityOnlineWrongSize",

    "missingStateAllOnline",
    "missingStateQuorumOnline",
    "missingStateMinorityOnline",

    "missingStateAllOnlineWrongSize",
    "missingStateQuorumOnlineWrongSize",
    "missingStateMinorityOnlineWrongSize",

    "unsupportedQueueTypes",
    "unidentified",
    "down",
    "stopped",
    "flow",
];

export const ALL_DOMAINS: Domain[] = [
    {
        "label": "Beta",
        "id": "beta"
    },
    {
        "label": "Gamma",
        "id": "gamma"
    },
    {
        "label": "Prod",
        "id": "prod"
    }
]

export type WorkflowStatus = {
    label: string;
    id: string;
}

export type Broker = {
    id: string;
    region: string; // airport code
    regionName: string;
    brokerDetails: BrokerDetails;
    summary: BrokerSummary;
    brokerInfo: BrokerInfo;
    snapshotId: string;
    instanceHistory: InstanceHistory[];
    maintenanceInfo: BrokerMaintenanceInfo;
}

export enum VolumeType {
    ROOT_VOLUME = "ROOT_VOLUME",
    DATA_VOLUME = "DATA_VOLUME"
}

export type Ec2Volume = {
    volumeId: string;
    size: number;
    ebsStatsLink: string;
    volumeType: VolumeType;
}

export type Ec2Instance = {
    instanceId: string;
    title: string;
    ec2IsenLink: string;
    asgIsenLink: string;
    publicIp: string;
    privateIp: string;
    cpuCredits: string;
    brokerStatus: string;
    agentStatus: string;
    dataPartitionAvailability: number;
    launchTime: string;
    availabilityZone: string;
    ebsRootPartitionVolume: string;
    qualifiedBrokerId: string;
    eniId: string;
    scheduledForRetirement: boolean;
    volumes: Ec2Volume[]
}


export type EC2InstanceList = {
    instances: Ec2Instance[]
}

// Mapping from instanceId -> privateIp
export type NodeMapping = {
    [instanceId: string]: string;
}

export type BrokerMaintenanceInfo = {
    currentConfiguration: BrokerConfigEntry;
    pendingConfiguration: BrokerConfigEntry;
    maintenanceWindow: string;
    maintenanceFlag: boolean;
    autoMinorVersionUpgrade: boolean;
    nextMaintenance: string;
}

export type BrokerConfigEntry = {
    configurationId: string;
    isenLink: string;
    revision: string;
    blobId: string;
    description: string;
    invalid: boolean | null;
}

export type Dashboard = {
    label: string;
    id: string;
}

export type Config = {
    username: string
}

export type Domain = {
    label: string,
    id: string
}

export type Link = {
    name: string;
    href: string;
}

export type Region = {
    regionCode: string;
    airportCode: string;
}

export enum BrokerEngineType {
    ACTIVEMQ = "ACTIVEMQ",
    RABBITMQ = "RABBITMQ"
}

export type BrokerEFS = {
    id: string;
}

export type BrokerInfo = {
    arn: string;
    creationDate: string;
    publiclyAccessible: boolean;
    brokerAlarms: BrokerAlarm[];
    codeDeploy: string;
    codeDeployLogsLink: Link;
    cfnStackLink: Link;
    ebsDataPartition: string;
    engine: string;
    pendingEngine: string;
    pendingHostInstanceType: string;
    encryptionOptions: string;
    cellId: string;
    cellIsenLink: string;
    cellAccountId: string;
    authenticationStrategy: string;
    controlPlaneAccountId: string;
    controlPlaneAccountIsenLink: string;
    nodeMapping: NodeMapping;
    efsInfo: string;
    efs: BrokerEFS | undefined;
}

export type BrokerInstance = {
    instanceId: string
}

export type BrokerAlarm = {
    name: string;
    link: string;
}

export enum DRMode {
    NONE = "NONE",
    CRDR = "CRDR"
}

export enum ReplicationRole {
    PRIMARY = "PRIMARY",
    RECOVERY = "RECOVERY"
}

export type DrCounterPart = {
    region: string;
    brokerId: string;
}
export enum BrokerDeploymentMode {
    SINGLE_INSTANCE = "SINGLE_INSTANCE",
    MASTER_SLAVE_MULTI_AZ = "MASTER_SLAVE_MULTI_AZ",
    CLUSTER_MULTI_AZ = "CLUSTER_MULTI_AZ"
}

export type BrokerSummary = {
    serviceAccountId: string;
    name: string;
    brokerState: string;
    accountId: string;
    accountLink: string;
    hostInstanceType: string;
    brokerEngineType: BrokerEngineType;
    deploymentMode: BrokerDeploymentMode;
    brokerTags: string[];
    brokerInstanceInternalTags: string[];
    quarantineStates: string;
    ourBroker: boolean;
    drMode: DRMode;
    pendingDrMode: DRMode;
    replicationRole: ReplicationRole | undefined;
    drCounterPart: DrCounterPart | undefined;
}

export type BrokerDetails = {
}

export type InstanceHistory = {
    instanceId: string;
    createTime: number;
}

export type LogStream = {
    canonicalName: string,
    id: string,
    instanceId: string,
    isenLink: string,
    latestTimestamp: string,
    latestTimestampEpochMillis: number,
    logGroupName: string,
    logStreamName: string,
    title: string
}

export type LogStreamList = {
    logStreams: LogStream[]
}

export type Workflow = {
    workflowName: string,
    brokerId: string,
    region: string,
    executionArn: string,
    link: string
}

export type WorkflowList = {
    workflows: Workflow[]
}

export type EventHistoryEntry = {
    brokerState: string,
    eventType: string,
    eventData: string,
    message: string,
    timeStamp: string
}

export type LogEntry = {
    log: string
}

export type LogEntryList = {
    logs: LogEntry[]
}

export type BrokerInstanceMemoryBreakdownUnit = {
    bytes: number,
    percentage: number
}

export type BrokerInstanceMemoryBreakdown = {
    status: "SUCCESS" | "FAILED",
    memoryBreakdown: Map<string, BrokerInstanceMemoryBreakdownUnit>
}

export type BrokerMemoryBreakdown = {
    instances: Map<string, BrokerInstanceMemoryBreakdown>
}

export type BrokerEnvironment = {
    brokerEnvironment: object
}

export type ClusterStatus = {
    clusterStatus: {
        alarms: ClusterStatusAlarm[];
        cluster_name: string;
        cpu_cores: ClusterStatusCpuCore[];
        disk_nodes: string[];
        feature_flags: ClusterStatusFeatureFlag[];
        listeners: ClusterStatusListener[];
        maintenance_status: ClusterStatusMaintenanceStatus[];
        partitions: any;
        ram_nodes: any;
        running_nodes: string[];
        versions: ClusterStatusVersion[];
    };
}

export type ClusterStatusAlarm = {
    node: string;
    resource: string;
    type: string;
}

export type ClusterStatusCpuCore = {
    [key: string]: number;
}

export type ClusterStatusFeatureFlag = {
    desc: string;
    doc_url: string;
    name: string;
    provided_by: string;
    stability: string;
    state: string;
}

export type ClusterStatusListener = {
    [key: string]: {
        interface: string;
        node: string;
        port: number;
        protocol: string;
        purpose: string;
    }
};

export type ClusterStatusMaintenanceStatus = {
    [key: string]: string;
};

export type ClusterStatusVersion = {
    [key: string]: {
        erlang_version: string;
        rabbitmq_name: string;
        rabbitmq_version: string;
    }
}

export type RabbitBrokerQueue = {
    name: string,
    type: string,
    state: string,
    vhost: string,
    primaryNode: string
}

export type RabbitBrokerQueues = {
    queues: RabbitBrokerQueue[]
}

export type RabbitBrokerConfigOverride = {
    brokerId: string,
    config: string
}

export type RabbitBrokerPolicies = {
    brokerPolicies: {
        policies: {
            vhost: string,
            name: string,
            pattern: string,
            applyTo: string,
            definition: string,
            priority: number
        },
        operatorPolicies: {
            vhost: string,
            name: string,
            pattern: string,
            applyTo: string,
            definition: string,
            priority: number
        }
    }
}

export type SimMarkdown = {
    markdown: string
}

export type SimIssue = {
    creationDate: string,
    simLink: string,
    title: string
}

export type SimIssueList = {
    simIssues: SimIssue[]
}

export type ReachoutTemplate = {
    templateId: string,
    description: string,
    title: string
}

export type ReachoutTemplateList = {
    reachoutTemplates: ReachoutTemplate[]
}

export type HOTWExecution = {
    timestamp: number,
    sopName: string,
    executionStatus: string,
    actionTaken: boolean,
    details: string[]
}

export type HOTWExecutionsList = {
    executions: HOTWExecution[]
}

export type QualifiedFailoverEvent = {
    failoverEvent: FailoverEvent,
    roleAfterFailover: ReplicationRole
}

export type FailoverEvent = {
    arcUpdateTime: number,
    initiationTime: number,
    completionTime: number,
    mode: string,
    roleAfterFailover: string
}

export type QualifiedFailoverEventList = {
    failoverHistory: QualifiedFailoverEvent[]
}

export type CustomerMetricName = {
    type: string,
    name: string
}

export type CustomerMetricNamesList = {
    customerMetricNames: CustomerMetricName[]
}

export type CustomerMetricData = {

}

export type CloudwatchDashboardLink = {
    link: string
}

export type EventHistoryEntryList = {
    events: EventHistoryEntry[]
}
