import React, { memo } from 'react';
import { Broker, BrokerEngineType, Ec2Instance } from '../types';
import * as Arn from "@aws-sdk/util-arn-parser";
import CloudWatchDashboard from './CloudWatchDashboard';
import { getActiveBrokerMetrics } from './metrics/activeBrokerMetrics';
import { getRabbitBrokerMetrics } from './metrics/rabbitBrokerMetrics';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[]
}

const BrokerMetricsTab : React.FC<Props> = ({broker, brokerInstances}) => {
    let region = Arn.parse(broker.brokerInfo.arn).region;
    let widgets;
    if (broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
        widgets = getActiveBrokerMetrics(broker);
    } else if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        widgets = getRabbitBrokerMetrics(broker, brokerInstances);
    } else {
        throw new Error("Unknown engine type: " + broker.summary.brokerEngineType);
    }

    return (
        <div>
            <CloudWatchDashboard
                broker={broker}
                layoutType={broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ ? 'manual' : 'automatic'}
                serviceAccountId={broker.summary.serviceAccountId}
                widgets={widgets}
                widgetsPerRow={2}
                periodOverride={broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ ? 'auto' : 'inherit'}
            /> 
        </div>
    )
}

export default memo(BrokerMetricsTab);