import React, { memo } from 'react';
import { Broker, Ec2Instance } from '../types';
import * as Arn from "@aws-sdk/util-arn-parser";
import CloudWatchDashboard from './CloudWatchDashboard';
import { getRabbitQueuesMetrics } from './metrics/rabbitQueuesMetrics';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[]
}

const RabbitQueuesTab : React.FC<Props> = ({broker, brokerInstances}) => {
    let region = Arn.parse(broker.brokerInfo.arn).region;
    let widgets = getRabbitQueuesMetrics(broker, brokerInstances);

    return (
        <div>
            <CloudWatchDashboard
                broker={broker}
                layoutType='manual'
                widgetsPerRow={2}
                serviceAccountId={broker.summary.serviceAccountId}
                widgets={widgets}
            /> 
        </div>
    )
}

export default memo(RabbitQueuesTab);